<template>
  <b-card>
    <b-container fluid>
      <!-- Main table element -->
      <b-table
        show-empty
        stacked="md"
        :items="items"
        :fields="fields"
        :per-page="perPage"
      >
        <template slot="value" slot-scope="row">
          <div v-if="row.detailsShowing">
            <input type="text" v-model="row.item.value" />
          </div>
          <div v-else>
            {{ row.value }}
          </div>
        </template>

        <template v-slot:cell(value)="row">
          <div v-if="row.detailsShowing">
            <input type="text" v-model="row.item.value" />
          </div>
          <div v-else>
            {{ row.value }}
          </div>
        </template>

        <template v-slot:cell(actions)="row">
          <div v-if="row.detailsShowing">
            <b-button size="sm" @click="updateRow(row.item)" class="mr-1">
              <div @click="row.toggleDetails">
                Save
              </div>
            </b-button>
          </div>
          <div v-else>
            <b-button size="sm">
              Edit
            </b-button>
          </div>
        </template>

        <template v-slot:cell(row-details)="row">
          <b-card>
            <b-row class="mb-4">
              <b-col sm="3" class="text-sm-right"><b>Value:</b></b-col>
              <b-col>
                <input type="text" v-model="row.item.value" />
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
    </b-container>
  </b-card>
</template>

<script>
import Vue from 'vue'
export default {
  data() {
    return {
      items: [],
      fields: [
        { key: 'name', label: 'Config' },
        { key: 'value', label: 'Value' },
        { key: 'description', label: 'Description' },
        { key: 'actions', label: 'Actions' }
      ],
      perPage: 10,
      pageOptions: [10, 20, 30]
    }
  },
  mounted() {
    var vm = this
    // this.totalRows = this.items.length
    this.$http
      .get('/api/super_admin/app_configs', { handleErrors: true })
      .then(res => {
        let configs = res.data
        configs.forEach(config => {
          config._showDetails = true
        })
        vm.items = configs
        vm.totalRows = res.data.length
      })
  },
  methods: {
    updateRow(item) {
      this.$http
        .patch(
          '/api/super_admin/app_configs/' + item.id,
          {
            value: item.value
          },
          {
            handleErrors: false
          }
        )
        .then(res => {
          this.reportId = null
          Vue.toasted.show(res.data.message, {
            icon: 'user-circle',
            type: 'success'
          })
        })
    }
  }
}
</script>
<style>
.b-table-details {
  display: none;
}
</style>
